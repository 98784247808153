<template>
  <div class="about">
    <h1>Nosotros</h1>
    <div class="contenedor">
      <div class="contenedor-item" ref="item0" key="item0">
        <div class="temp" key="0" v-show="!visible[0]" />
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '-20px', y: '0' }"
          :posFinal="{ x: '0', y: '0' }"
          :duracion="700"
        >
          <img src="../assets/nosotros.png" alt="" v-show="visible[0]" />
        </Deslizar>
      </div>
      <div class="contenedor-item">
        <div class="temp" v-show="!visible[0]" />
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '100px', y: '0' }"
          :posFinal="{ x: '0', y: '0' }"
          :duracion="1300"
        >
          <p v-show="visible[0]">
            Fundada en 1979, Ingeniería y Manufactura Sigue, S.A. de C.V. cuenta
            con la experiencia de 3 generaciones de servicio especializado,
            puesta a su disposición para el diseño y fabricación de piezas metal
            mecánicas.
          </p>
        </Deslizar>
      </div>
      <div class="contenedor-item" ref="item1" key="item1">
        <div class="temp" v-show="!visible[1]" />
        <Deslizar
          :name="'deslizarOcultar'"
          :ocultar="true"
          :posInicial="{ x: '-20px', y: '0' }"
          :posFinal="{ x: '0', y: '0' }"
          :duracion="700"
        >
          <img
            ref="item2"
            src="../assets/zoomCertificado.png"
            alt=""
            style="min-width:300px; max-width:100%"
            v-show="visible[1]"
          />
        </Deslizar>
      </div>
      <div class="temp" v-show="!visible[1]" />
      <Deslizar
        :name="'deslizarOcultar'"
        :ocultar="true"
        :posInicial="{ x: '100px', y: '0' }"
        :posFinal="{ x: '0', y: '0' }"
      >
        <div class="contenedor-item" v-show="visible[1]">
          <p>
            Nos enfocamos a la satisfacción de sus requerimientos en nuestros
            productos y servicios, procurando una excelsa calidad en el proceso.
          </p>
          <p>
            Además, puede beneficiarse de nuestra amplia disponibilidad de
            servicio, las 24 horas todo el año y de la flexibilidad en el
            volumen de su orden en la manufactura de piezas.
          </p>
        </div>
      </Deslizar>
    </div>
  </div>
</template>
<script>
import Deslizar from "@/components/Deslizar.vue";

export default {
  name: "About",
  components: {
    Deslizar
  },
  data() {
    return {
      observer: {},
      visible: []
    };
  },
  mounted() {
    let items = Array(this.$refs.item0, this.$refs.item1);

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio >= 0.4) {
            this.observer.unobserve(entry.target);
            let indice = entry.target.getAttribute("idref");
            this.visible.splice(indice, 1, true);
          }
        });
      },
      { threshold: 0.4 }
    );

    items.forEach((el, index) => {
      this.observer.observe(el);
      el.setAttribute("idref", index);
      this.visible[index] = false;
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  }
};
</script>
<style lang="stylus" scoped>
.about
  align-self center
  @media screen and (min-width: $sm)
    width 60vw
  p
    text-align left
    margin-top 0
.temp
  width 300px
  height 350px
  margin 1rem
</style>
